<template>
  <div class="map-cover">
    <GmapMap
      :center="location"
      :zoom="8"
      style="width: 100%; height: 350px"
      @click="$emit('click', $event)"
      map-type-id="roadmap"
    >
      <GmapMarker
        v-for="(marker, i) in markers"
        :key="i"
        :clickable="true"
        :draggable="true"
        :position="{ lat: marker.lat, lng: marker.lng }"
      />
      <gmap-polyline
        :key="keyUpdated"
        v-bind:path.sync="path"
        v-bind:options="{ strokeColor: 'red' }"
      >
      </gmap-polyline>
    </GmapMap>
  </div>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      default: () => {},
    },
    markers: {
      type: Array,
      default: () => [],
    },
    path: {
      type: Array,
      default: () => [],
    },
    keyUpdated:{
        type: Boolean,
        default: false
    }
  },
};
</script>

<style></style>
