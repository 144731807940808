<template>
  <div class="add-shipment mt-10">
    <div class="max-width" :class="isEdit ? 'px-0' : ''">
      <v-form
        v-model="valid"
        @submit.prevent="newStepHandler"
        ref="addShipmentForm"
      >
        <v-row align="start">
          <v-col cols="12" md="5" style="flex-direction: column">
            <div class="h-100" style="display: flow-root">
              <v-row class="h-100" align="start" justify="center">
                <v-col cols="6" v-if="images.length > 0">
                  <slick-carousel
                    class="circle-dots text-center"
                    v-bind="settings"
                    :key="keyUpdated"
                  >
                    <div
                      v-for="(item, i) in images"
                      :key="i"
                      class="cursor-pointer"
                    >
                      <v-icon class="delete" @click="deleteImage(i, item)"
                        >mdi-close</v-icon
                      >
                      <div
                        class="slide-img"
                        :style="{
                          background: 'url(' + item.url + ') center center',
                        }"
                      ></div>
                    </div>
                  </slick-carousel>
                </v-col>
                <!-- <v-col cols="6" v-else-if="item.image && isEditProduct">
                  <div class="cursor-pointer text-center">
                    <v-icon class="delete-img" @click="deleteImage(i, item)"
                      >mdi-close</v-icon
                    >
                    <div
                      class="slide-img"
                      :style="{
                        background: 'url(' + item.image + ') center center',
                      }"
                    ></div>
                  </div>
                </v-col> -->
                <v-col
                  :cols="images.length > 0 || isEditProduct ? 6 : 12"
                  class="text-center"
                >
                  <base-upload-text
                    class="pt-5"
                    @change="uploadImage"
                  ></base-upload-text>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12" md="7">
            <v-row>
              <v-col cols="12" md="6">
                <base-input
                  :placeholder="$t('shipmentName')"
                  v-model="obj.title"
                ></base-input>
              </v-col>
              <v-col cols="12" md="6">
                <base-select
                  :placeholder="$t('category')"
                  :items="categories"
                  v-model="obj.category_id"
                ></base-select>
              </v-col>
              <v-col cols="12" md="6">
                <base-date
                  :key="keyDate"
                  v-model="obj.shipment_date"
                  @handleDate="dateHandler"
                  :placeholder="$t('deliveryDate')"
                ></base-date>
              </v-col>
              <v-col cols="12" md="6">
                <base-input
                  :placeholder="$t('weight')"
                  :isWeight="true"
                  v-model="obj.weight"
                  itemText="title"
                  itemValue="weight"
                  type="number"
                ></base-input>
              </v-col>
              <v-col cols="12">
                <base-input
                  :placeholder="$t('budget')"
                  v-model="obj.budget"
                  :isBudget="true"
                  type="number"
                ></base-input>
              </v-col>
              <v-col cols="12" v-if="obj.price">
                <base-input
                  :placeholder="$t('price')"
                  v-model="obj.price"
                  :isBudget="true"
                  type="number"
                ></base-input>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="!isEditProduct">
          <v-col cols="5" ></v-col>
          <v-col cols="12" md="7">
            <text-area-vue
            :placeholder="$t('notes')"
              v-model="obj.note"
              :rules="[]"
              >
            </text-area-vue>
          </v-col>
        </v-row>
        <div class="text-center mt-14" v-if="!isEdit">
          <v-btn
            class="c-btn"
            color="secondary"
            min-width="200"
            height="40"
            type="submit"
            ><span class="white--text font-300 font-20">{{
              $t("next")
            }}</span></v-btn
          >
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TextAreaVue from "../../../components/base/TextArea.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isEditProduct: {
      type: Boolean,
      default: false,
    },
  },
  components:{TextAreaVue},
  data: () => ({
    settings: {
      arrows: false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
    valid: false,
    images: [],
    obj: { shipment_date: "" },
    keyUpdated: false,
    keyDate: false,
  }),
  computed: {
    ...mapGetters(["categories", "weightItems"]),
  },
  watch: {
    obj: {
      handler() {
        this.obj.images = this.images;
      },
      deep: true,
    },
    // images(val){

    // }
  },
  methods: {
    uploadImage(file) {
      if (this.images.length <= 3) {
        this.images.push({
          url: URL.createObjectURL(file),
          file,
        });
        this.keyUpdated = !this.keyUpdated;
      } else {
        this.$store.dispatch("showSnack", {
          text: this.$t("cantAddfourImage"),
          color: "error",
        });
      }
    },
    newStepHandler() {
      this.obj.images = [...this.images];
      let valid = this.$refs.addShipmentForm.validate();
      if (!valid) {
        return;
      }
      if (this.images.length == 0) {
        this.$store.dispatch("showSnack", {
          text: this.$t("one image require at least"),
          color: "error",
        });
        return;
      }
      this.$emit("next", this.obj);
    },
    async deleteImage(idx, editImage) {
      if (this.isEdit) {
        if (this.images.length == 1) {
          this.$store.dispatch("showSnack", {
            text: "You can't delete all images",
            color: "error",
          });
          return;
        }
        if (editImage.id) {
          let formData = new FormData();
          formData.append("shipment_image_id", editImage.id);
          let { data } = await this.$axios.post(
            `deleteImageShipment`,
            formData
          );
          if (data.success) {
            this.$store.dispatch("showSnack", {
              text: "Deleted Successfully",
              color: "success",
            });
            this.images = [...this.images.filter((_, i) => idx != i)];
          } else {
            this.$store.dispatch("showSnack", {
              text: data.message,
              color: "error",
            });
          }
        } else {
          this.images = [...this.images.filter((_, i) => idx != i)];
        }
      } else {
        this.images = [...this.images.filter((_, i) => idx != i)];
      }
    },
    dateHandler(event) {
      this.obj.shipment_date = event;
      this.obj.date = event;
      this.keyDate = !this.keyDate;
    },
  },
  created() {
    if (this.isEdit) {
      this.obj = this.item;
      this.obj.category_id = this.item.category.id;
      this.obj.shipment_date = this.item.date;
      this.images = [];
      this.item.images.forEach((img) => {
        this.images.push({
          url: img.image,
          id: img.id,
        });
      });
    }
  },
};
</script>

<style lang="scss" >
.slide-img {
  height: 140px !important;
  width: 150px !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  margin: auto !important;
  border-radius: 10px;
}
.add-shipment {
  .img-cover-main {
    height: 350px;
  }
}
</style>
