<template>
  <div class="google_map">
    <div v-if="!isEditProduct">
      <!-- origin -->

      <v-row class="mb-5">
        <v-col cols="12" md="8">
          <google-map
            @click="addLocation($event, 'origin')"
            :keyUpdated="keyUpdated"
            :location="originLocation"
            :markers="markerOrigin"
          ></google-map>
        </v-col>
        <v-col cols="12" md="4">
          <v-row>
            <v-col cols="12">
              <h2 class="font-22 font-500 d-block blue--text">
                <img
                  width="15"
                  height="15"
                  :src="require('@/assets/images/icons/address.png')"
                  alt=""
                />{{ $t("originAddress") }}
              </h2>
              <vue-google-autocomplete
                v-if="reloaded && !isEdit"
                v-model="addressOrigin"
                ref="address"
                id="map"
                outlined
                classname="form-control place-input mb-2"
                types="geocode"
                v-on:placechanged="getAddressData($event, 'origin')"
                :placeholder="$t('goQuicklyToCity')"
              >
              </vue-google-autocomplete>
            </v-col>
            <!-- country -->
            <v-col cols="12">
              <view-field
                class="shadow_"
                :value="obj.country_origin"
                :title="$t('country')"
              ></view-field>
            </v-col>
            <!-- city -->
            <v-col cols="12">
              <view-field
                class="shadow_"
                :value="obj.city_origin"
                :title="$t('city')"
              ></view-field>
            </v-col>
            <!-- address -->
            <v-col cols="12">
              <view-field
                class="shadow_"
                :value="obj.address_origin"
                :title="$t('address')"
              ></view-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <!-- destination -->

    <v-row>
      <v-col cols="12" md="8">
        <google-map
          @click="addLocation($event, 'destination')"
          :keyUpdated="keyUpdated"
          :location="destinationLocation"
          :markers="markerDestination"
        ></google-map>
      </v-col>

      <v-col cols="12" md="4">
        <v-row>
          <v-col cols="12">
            <h2 class="font-22 font-500 d-block blue--text">
              <img
                width="15"
                height="15"
                :src="require('@/assets/images/icons/address.png')"
                alt=""
              />
              {{ $t("orginAddress") }}
            </h2>
            <vue-google-autocomplete
              class="input_map"
              v-if="reloadedDestination"
              v-model="addressDestination"
              ref="address"
              id="map2"
              outlined
              classname="form-control place-input mb-2"
              types="geocode"
              v-on:placechanged="getAddressData($event, 'destination')"
              :placeholder="$t('goQuicklyToCity')"
            >
            </vue-google-autocomplete>
          </v-col>
          <!-- country -->
          <v-col cols="12" v-if="!isEditProduct">
            <view-field
              class="shadow_"
              :value="obj.country_destination"
              title="country"
            ></view-field>
          </v-col>
          <!-- city -->
          <v-col cols="12">
            <view-field
              class="shadow_"
              :value="obj.city_destination"
              title="city"
            ></view-field>
          </v-col>
          <!-- address -->
          <v-col cols="12">
            <view-field
              class="shadow_"
              :value="obj.address_destination"
              title="address"
            ></view-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div
      class="d-flex align-center justify-center text-center pb-3"
      v-if="!isEdit"
    >
      <v-btn
        class="c-btn mt-14 mx-1"
        color="grey"
        height="40px"
        @click="$emit('back')"
        ><span class="white--text font-300 font-20 d-block px-6">{{
          $t("back")
        }}</span></v-btn
      >
      <v-btn
        :disabled="loading"
        @click="nextHandler"
        color="primary"
        class="mx-2 mt-14"
        height="40px"
      >
        {{ $t("addShipment") }}
        <v-progress-circular
          v-if="loading"
          class="mx-2"
          :size="15"
          :width="2"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GoogleMap from "../../../components/base/GoogleMap.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isEditProduct: {
      type: Boolean,
      default: false,
    },
  },
  components: { GoogleMap },
  data: () => ({
    obj: {
      latitude_origin: 123,
      longitude_origin: 103,
      latitude_destination: 173,
      longitude_destination: 133,
    },
    destinationCities: [],
    originCities: [],
    valid: false,
    address: "",
    markerOrigin: [{ lng: null, lat: null }],
    markerDestination: [{ lng: null, lat: null }],
    originLocation: { lat: 33.5138, lng: 36.2765 },
    destinationLocation: { lat: 33.5138, lng: 36.2765 },

    addressDestination: "",
    addressOrigin: "",
    reloaded: false,
    reloadedDestination: false,
    keyUpdated: false,
  }),
  computed: {
    ...mapGetters(["countries"]),
  },
  methods: {
    async addLocation(event, type) {
      let response = await this.$store.dispatch("setMapMarker", event);
      let lng = event.latLng.lng();
      let lat = event.latLng.lat();

      if (response.country) {
        this.obj["address_" + type] = response.fullAddress;
        this.obj["country_" + type] = response.country;
        this.obj["city_" + type] = response.city;
        this.keyUpdated = !this.keyUpdated;
        if (type == "origin") {
          this.markerOrigin[0].lng = lng;
          this.markerOrigin[0].lat = lat;
          this.obj.latitude_origin = lat;
          this.obj.longitude_origin = lng;
        } else {
          this.markerDestination[0].lng = lng;
          this.markerDestination[0].lat = lat;
          this.obj.latitude_destination = lat;
          this.obj.longitude_destination = lng;
        }
      } else {
        this.obj["address_" + type] = "";
        this.obj["country_" + type] = "";
        this.obj["city_" + type] = "";
        this.$store.dispatch("showSnack", {
          text: "Not Available Address",
          color: "error",
          timeout: 2000,
        });
        this.keyUpdated = !this.keyUpdated;
      }
    },
    getAddressData(addressData, type) {
      if (addressData) {
        this[type + "Location"].lat = addressData.latitude;
        this[type + "Location"].long = addressData.longitude;
      }
    },
    countryHandler(event, type) {
      if (type == "destination") {
        this.destinationCities = this.countries.filter(
          (country) => country.id == event
        )[0].cities;
      } else {
        this.originCities = this.countries.filter(
          (country) => country.id == event
        )[0].cities;
      }
    },
    nextHandler() {
      if (!this.obj.country_destination || !this.obj.country_origin) {
        this.$store.dispatch("showSnack", {
          text: "Destination and Origin address must be selected",
          color: "error",
        });
        return;
      }
      this.$emit("submit", this.obj);
    },
  },
  created() {
    if (this.isEdit) {
      this.obj = this.item;
      this.markerOrigin[0].lat = +this.item.address_lat_origin;
      this.markerOrigin[0].lng = +this.item.address_long_origin;
      this.markerDestination[0].lat = +this.item.address_lat_destination;
      this.markerDestination[0].lng = +this.item.address_long_destination;
      this.center = this.markers[0];

      this.originLocation = this.markers[0];
      this.destinationLocation = this.markers[1];
      this.keyUpdated = !this.keyUpdated;
    }
  },
  mounted() {
    setTimeout(() => {
      this.reloaded = true;
      this.reloadedDestination = true;
    }, 1000);
  },
};
</script>

<style lang="scss">
.google_map {
  .shadow_ {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.09) !important;
  }
  .input_map {
    border: 1px solid #ddd;
  }
}
</style>
